import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'
import { slide as Menu } from 'react-burger-menu'

import { Box } from '@rebass/emotion'

import styled from '../../styles/styled'

const burgerStyles = {
  bmBurgerButton: {
    position: 'absolute',
    left: '1rem',
    top: '1.4rem',
    borderRadius: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem 1rem',
    height: '1.4rem',
    zIndex: '1',
    width: '15px',
  },
  bmBurgerBars: {
    background: '#082E43',
    borderRadius: '50px',
  },
  bmIcon: {
    width: 'auto',
    height: 'auto',
  },
  bmCrossButton: {
    height: '1.875rem',
    width: '1.875rem',
    right: '1.8rem',
    top: '1.5rem',
  },
  bmCross: {
    width: '0.125rem',
    height: '1.875rem',
    background: '#FFFFFF',
  },
  bmMenu: {
    width: 'auto',
  },
  bmMenuWrap: {
    top: '0',
    width: '35%',
    minWidth: '16rem',
  },
  bmItemList: {
    width: 'auto',
    left: '0',
  },
  bmMorphShape: {
    fill: '#36454f',
    width: 'auto',
  },
  bmOverlay: {
    right: '0',
    top: '0',
  },
}

const NavWrapper = styled(Menu)`
  background: ${props => props.theme.colors.black};
  padding: 3rem 2rem;
`

const StyledText = styled.span`
  color: ${props => props.theme.colors.white};
`

const List = styled.ul`
  outline: none;
`

export const HamburgerMenuWithoutData = ({ data }) => {
  const renderListItem = link => {
    return (
      <li key={link.url}>
        <Box mb={3}>
          {link.external_url ? (
            <a href={link.external_url} target="_blank" rel="noreferrer">
              <StyledText>{link.display_text}</StyledText>
            </a>
          ) : (
            <Link to={link.url}>
              <StyledText>{link.display_text}</StyledText>
            </Link>
          )}
        </Box>
      </li>
    )
  }

  return (
    <NavWrapper
      styles={burgerStyles}
      right={true}
      customBurgerIcon={
        <div>
          <svg width="28" height="21" xmlns="http://www.w3.org/2000/svg">
            <g
              fillRule="nonzero"
              stroke="#36454f"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
            >
              <path d="M1.5 1h25" />
              <path d="M1.5 10h25" />
              <path d="M1.5 19h25" />
            </g>
          </svg>
        </div>
      }
    >
      <List>
        {renderListItem(data.contentstackHeader.navigation_link[0])}

        <li>
          <StyledText>{data.contentstackHeader.solutions_item_text}</StyledText>

          <Box pl={4} my={3}>
            <ul>
              <li>
                <Box mb={3}>
                  <Link to={data.contentstackHeader.travel_item_url}>
                    <StyledText>
                      {data.contentstackHeader.travel_item_text}
                    </StyledText>
                  </Link>
                </Box>
              </li>
              <li>
                <Box  mb={3}>
                  <Link to={data.contentstackHeader.marketplaces_item_url}>
                    <StyledText>
                      {data.contentstackHeader.marketplaces_item_text}
                    </StyledText>
                  </Link>
                </Box>
              </li>
              <li>
                <Box>
                  <Link to={data.contentstackHeader.global_scale_item_url}>
                    <StyledText>
                      {data.contentstackHeader.global_scale_item_text}
                    </StyledText>
                  </Link>
                </Box>
              </li>
            </ul>
          </Box>
        </li>

        {data.contentstackHeader.navigation_link.slice(1).map(renderListItem)}
      </List>
    </NavWrapper>
  )
}

export const HamburgerMenu = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackHeader {
          solutions_item_text
          travel_item_text
          travel_item_url
          marketplaces_item_text
          marketplaces_item_url
          global_scale_item_text
        	global_scale_item_url
          navigation_link {
            display_text
            url
            external_url
          }
        }
      }
    `}
    render={data => <HamburgerMenuWithoutData data={data} {...props} />}
  />
)
