import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import DotsSvg from '../../images/dots.svg'
import FacebookLogo from '../../images/Facebook.png'
import InstagramLogo from '../../images/Instagram.png'
import TwitterLogo from '../../images/Twitter.png'
import YapstoneIconSvg from '../../images/Yapstone_Icon_White.svg'
import YapstoneLogoWhite from '../../images/Yapstone_Logo.svg'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { CurveBox, EyeBrowText, SmallParagraphText } from '../atoms'
import { ConstrainedWidthContainer } from '../molecules'

const Container = styled(Flex)`
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding-top: 10rem;
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.white};
`

const StyledAnchor = styled.a`
  color: ${props => props.theme.colors.white};
`

const SocialIcon = styled.a`
  margin-right: 1rem;
`

const WhiteEyeBrowText = styled(EyeBrowText)`
  color: ${props => props.theme.colors.white};
`

const Dots = styled(Box)`
  background-image: url('${DotsSvg}');
  height: 25%;
  width: 80%;
  position: absolute;
  top: -65px;
  left: -30%;
  z-index: 1;
  transform: translateX(50%);
  mix-blend-mode: darken;
`

const StyledCurveBox = styled(CurveBox)`
  height: 30px;
  transform: rotate(180deg);
`

const YapstoneIcon = styled(Box)`
  background-image: url('${YapstoneIconSvg}');
  box-shadow: 0px 3px 15px rgba(54, 69, 79, 0.4);
  border-radius: 100%;
  height: 80px;
  width: 80px;
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translateX(-50%) translateY(-69px);
  z-index: 1;

  ${Mq.sm} {
    display: block;
    top: 110px;
  }
`

const StyledFooter = styled.footer`
  position: relative;
`

const SectionsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.7rem 1rem;

  ${Mq.md} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.8rem 0.8rem;
  }
`

const FooterWithoutData = ({ data }) => (
  <StyledFooter>
    <Dots />
    <YapstoneIcon />
    <StyledCurveBox />

    <Container>
      <ConstrainedWidthContainer>
        <Flex flexWrap="wrap" pt={4} mx={[0, 5]}>
          <Box mx="auto" width={[1, 1, 1, 3 / 12]}>
            <Box mb={4}>
              <Link to="/">
                <img src={YapstoneLogoWhite} alt="Yapstone Logo" />
              </Link>
            </Box>

            <Box mb={[2, 2, 2, 0]} pb={5}>
              <p>
                <SmallParagraphText>
                  {data.contentstackFooter.description}
                </SmallParagraphText>
              </p>
            </Box>
          </Box>

          <Box width={[0, 1 / 12]} />

          <Box width={[1, 1, 1, 8 / 12]}>
            <SectionsContainer>
              {data.contentstackFooter.footer_section.map(section => (
                <Box key={section.section_title}>
                  <Box mb={4}>
                    <h2>
                      <WhiteEyeBrowText>
                        {section.section_title}
                      </WhiteEyeBrowText>
                    </h2>
                  </Box>

                  <ul>
                    {section.section_links.map(link => (
                      <li key={link.link_text}>
                        <Box mb={3}>
                          {link.external_link ? (
                            <StyledAnchor
                              href={link.external_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {link.link_text}
                            </StyledAnchor>
                          ) : (
                            <StyledLink to={link.link_url}>
                              {link.link_text}
                            </StyledLink>
                          )}
                        </Box>
                      </li>
                    ))}
                  </ul>
                </Box>
              ))}
            </SectionsContainer>
          </Box>

          <Box py={5}>
            <Box mb={3}>
              <SocialIcon href={data.contentstackFooter.facebook_url}>
                <img src={FacebookLogo} alt="Facebook Logo" />
              </SocialIcon>
              <SocialIcon href={data.contentstackFooter.twitter_url}>
                <img src={TwitterLogo} alt="Twitter Logo" />
              </SocialIcon>
              <SocialIcon href={data.contentstackFooter.instagram_url}>
                <img src={InstagramLogo} alt="Instagram Logo" />
              </SocialIcon>
            </Box>

            <div>{data.contentstackFooter.copyright_text}</div>
          </Box>
        </Flex>
      </ConstrainedWidthContainer>
    </Container>
  </StyledFooter>
)

export const Footer = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackFooter {
          description
          footer_section {
            section_title
            section_links {
              link_text
              link_url
              external_link
            }
          }
          facebook_url
          twitter_url
          instagram_url
          copyright_text
        }
      }
    `}
    render={data => <FooterWithoutData data={data} {...props} />}
  />
)
