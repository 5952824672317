import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

import { ContactModalContext } from '../../contexts'
import { Button } from '../atoms'

const ContactButtonWithoutData = ({ data, ...rest }) => (
  <ContactModalContext.Consumer>
    {({ setShowContactModal }) => (
      <Button
        width={[1, 'auto']}
        {...rest}
        onClick={() => setShowContactModal(true)}
      >
        {data.contentstackContactUsButton.text}
      </Button>
    )}
  </ContactModalContext.Consumer>
)

export const ContactButton = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackContactUsButton {
          text
        }
      }
    `}
    render={data => <ContactButtonWithoutData data={data} {...props} />}
  />
)
