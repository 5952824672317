import React, { ReactNode } from 'react'
import ReactModal from 'react-modal'

import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'

interface IProps extends ReactModal.Props {
  children: ReactNode
}

const StyledModal = styled(ReactModal)`
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  outline: none;
  box-shadow: none;
  background: ${props => props.theme.colors.white};
  text-align: left;

  ${Mq.md} {
    left: 50%;
    transform: translateX(-50%);
  }
`

export const Modal = ({ children, ...rest }: IProps) => {
  ReactModal.setAppElement('body')

  return (
    <StyledModal
      style={{
        overlay: {
          zIndex: '10',
          overflowY: 'auto',
        },
      }}
      {...rest}
    >
      {children}
    </StyledModal>
  )
}
