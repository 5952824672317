import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import DotsSvg from '../../images/dots.svg'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { Button, HeroHeadline, Paragraph, SmallParagraphText } from '../atoms'
import { Modal } from '../molecules'

interface IProps {
  open: boolean
  onClose: () => void
  person: {
    name: string
    position: string
    biography: string
    photoUrl: string
    linkedinUrl?: string
    twitterUrl?: string
  }
}

const ButtonContainer = styled(Box)`
  position: absolute;
  top: 2rem;
  right: 2rem;
`

const BioPosition = styled(Paragraph)`
  color: ${props => props.theme.colors.darkGray};
`

const BioImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`

const Dots = styled(Box)`
  background-image: url('${DotsSvg}');
  position: absolute;
  width: 12rem;
  height: 21rem;
  bottom: 5rem;
  left: -7rem;
  z-index: -1;
  display: none;

  ${Mq.xl} {
    display: block;
  }
`

const Dots2 = styled(Box)`
  background-image: url('${DotsSvg}');
  position: absolute;
  width: 12rem;
  height: 21rem;
  top: 2rem;
  right: -7rem;
  z-index: -1;
  display: none;

  ${Mq.xl} {
    display: block;
  }
`

const InfoContainer = styled(Box)`
  text-align: left;
  position: relative;
`

export const BioModal = ({ open, onClose, person }: IProps) => (
  <Modal isOpen={open} onRequestClose={onClose} contentLabel="Biography modal">
    <Flex flexWrap="wrap">
      <InfoContainer width={[1, 1, 1, 1, 6 / 12]}>
        <Dots />
        <Dots2 />
        <Box py={['5rem']} px={[3, 5, 6]}>
          <HeroHeadline>{person.name}</HeroHeadline>
          <BioPosition>
            <Paragraph>{person.position}</Paragraph>
          </BioPosition>
          <Box mt={5}>
            <SmallParagraphText>{person.biography}</SmallParagraphText>
          </Box>

          <Flex my={3}>
            {person.linkedinUrl ? (
              <a href={person.linkedinUrl}>Linkedin</a>
            ) : null}
            {person.twitterUrl ? <a href={person.twitterUrl}>Twitter</a> : null}
          </Flex>
        </Box>
      </InfoContainer>
      <Box width={[1, 1, 1, 1, 6 / 12]}>
        <ButtonContainer>
          <Button onClick={onClose}>Close</Button>
        </ButtonContainer>
        <BioImage src={person.photoUrl} alt={person.name} />
      </Box>
    </Flex>
  </Modal>
)
