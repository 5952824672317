import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'

interface IHeadingProps {
  noMargin?: boolean
}

export const HeroHeadline = styled.h1<IHeadingProps>`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontsizes.l};
  margin-bottom: ${props => (props.noMargin ? '0' : '1rem')};
  font-weight: 300;
  line-height: 0.97;

  ${Mq.md} {
    font-size: ${props => props.theme.fontsizes.xl};
  }
`

export const Headline = styled.h2<IHeadingProps>`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontsizes.m};
  margin-bottom: ${props => (props.noMargin ? '0' : '2rem')};
  line-height: 1.16;

  ${Mq.sm} {
    font-size: ${props => props.theme.fontsizes.l};
  }
  ${Mq.md} {
    font-size: ${props => props.theme.fontsizes.l};
    line-height: 0.95;
  }
`

export const SubHeadline = styled.h3`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontsizes.m};
  margin-bottom: 1.5rem;
  line-height: 1.16;

  ${Mq.md} {
    font-size: ${props => props.theme.fontsizes.m};
  }
`
