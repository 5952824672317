import { Box } from '@rebass/emotion'

import CurveSvg from '../../images/curve.svg'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'

export const CurveBox = styled(Box)`
  background-image: url('${CurveSvg}');
  background-size: 100% 100%;
  width: 100%;
  transform-origin: center center;
  position: absolute;
  background-position: bottom;
  background-repeat: no-repeat;

  ${Mq.sm} {
    height: 100px;
  }
`
