import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import DotsSvg from '../../images/dots.svg'
import GrowthGraphicImage from '../../images/Icon_Home_Growth 1.svg'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { Paragraph } from '../atoms'
import { CardShadowed, ContactButton } from './'

interface IProps {
  text: string
}

const Container = styled(Box)`
  z-index: 1;
  position: relative;
`

const Dots = styled(Box)`
  background-image: url('${DotsSvg}');
  position: absolute;
  width: 12rem;
  height: 18.1rem;
  top: 50%;
  left: -5.3rem;
  z-index: 0;
  display: none;
  transform: translateY(-50%);

  ${Mq.sm} {
    display: block;
  }
`

const GrowthGraphic = styled(Box)`
  background-image: url('${GrowthGraphicImage}');
  position: absolute;
  width: 8rem;
  height: 8rem;
  bottom: -0.6rem;
  right: -0.75rem;
  display: none;
  background-size: contain;
  background-repeat: no-repeat;

  ${Mq.sm} {
    display: block;
  }
`

export const ProductsGetInTouch = ({ text }: IProps) => {
  return (
    <Container>
      <Dots />

      <CardShadowed p={[2, 5]}>
        <Flex flexWrap="wrap" alignItems="center">
          <Box flex={['0 0 100%', '0 0 auto']} mr={[0, 5]}>
            <ContactButton width={[1, 'auto']} />
          </Box>

          <Box flex={['0 0 100%', 1]} order={[-1, 0]} mb={[3, 0]}>
            <Paragraph noMargin={true}>{text}</Paragraph>
          </Box>

          <GrowthGraphic />
        </Flex>
      </CardShadowed>
    </Container>
  )
}
