import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import { SVGDropper } from '../../components/atoms/'
import HeroSVG from '../../images/lottie/Company_Graphic_Hero.json'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { HeroHeadline, Paragraph } from '../atoms'
import { ConstrainedWidthContainer } from '../molecules'

const HeroImage = styled(Box)`
  height: 20rem;

  ${Mq.lg} {
    transform: translateX(-5%);
    height: 100%;
  }
`

const CompanyHeroWithoutData = ({ data }) => (
  <ConstrainedWidthContainer>
    <Flex flexWrap="wrap" mb={[3, '5.5rem']} justifyContent="space-between">
      <Box width={[1, 1, 1, 7 / 12]}>
        <HeroImage>
          <SVGDropper jsonObject={HeroSVG} loop={false} autoplay={true} />
        </HeroImage>
      </Box>

      <Box width={[1, 1, 1, 5 / 12]}>
        <Box py={[0, 5]}>
          <HeroHeadline>
            {data.contentstackCompanyPage.hero_headline}
          </HeroHeadline>
          <Paragraph>{data.contentstackCompanyPage.hero_description}</Paragraph>
        </Box>
      </Box>
    </Flex>
  </ConstrainedWidthContainer>
)

export const CompanyHero = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackCompanyPage {
          hero_headline
          hero_description
          hero_image {
            url
          }
        }
      }
    `}
    render={data => <CompanyHeroWithoutData data={data} {...props} />}
  />
)
