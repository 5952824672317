import styled from '../../styles/styled'

interface ITextProps {
  noMargin?: boolean
  inline?: boolean
}

interface IEyeBrowTextProps {
  light?: boolean
  color?: any // TODO: match this with theme colors?
  size?: string // TODO: make this match with theme font sizes?
}

export const Paragraph = styled.p<ITextProps>`
  font-size: ${props => props.theme.fontsizes.s};
  line-height: 1.4;
  margin-bottom: ${props => (props.noMargin ? '0' : '1rem')};
  white-space: pre-wrap;
  ${props => (props.inline ? 'display: inline-block;' : null)}
  font-family: ${props => props.theme.fonts.primary};
`

export const EyeBrowText = styled.span<IEyeBrowTextProps>`
  font-size: ${props => props.theme.fontsizes.xxs};
  letter-spacing: 0.125rem;
  font-weight: bold;
  line-height: 1.5;
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.purple};
  text-transform: uppercase;
`

export const SmallParagraphText = styled.span`
  font-size: ${props => props.theme.fontsizes.xs};
  line-height: 1.5;
`

export const LinkText = styled.span`
  font-weight: 500;
  font-size: ${props => props.theme.fontsizes.xs};

  &:hover {
    text-decoration: underline;
  }
`
