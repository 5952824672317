import { graphql, StaticQuery } from 'gatsby'
import React, { useState } from 'react'

import { Box, Flex } from '@rebass/emotion'

import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { Button, Paragraph, SubHeadline } from '../atoms'
import { ConstrainedWidthContainer, SelectorGroup } from '../molecules'

interface IMapContainerProps {
  locationImage: string
}

interface IVideoProps {
  show: boolean
}

const LocationContainer = styled(Box)`
  margin-top: -5rem;
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius};
  text-align: center;

  ${Mq.sm} {
    margin-top: -10rem;
  }
`

const VideoContainer = styled(Box)`
  overflow: hidden;
  height: 20rem;
`

const Video = styled.video<IVideoProps>`
  display: ${props => (props.show ? 'block' : 'none')};
  height: auto;
  margin: 0 auto;

  ${Mq.lg} {
    width: 100%;
  }
`

const LocationsWithoutData = ({ data }) => {
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(0)

  const selectorItems = data.contentstackCompanyPage.locations.map(
    (location, idx) => ({
      text: location.name,
      onClick: () => setSelectedLocationIndex(idx),
    }),
  )

  return (
    <Box my={5} py={5}>
      <VideoContainer>
        {data.contentstackCompanyPage.locations.map((location, idx) => (
          <Video
            key={idx}
            loop={true}
            autoPlay={true}
            muted={true}
            playsInline={true}
            // @ts-ignore
            disableRemotePlayback={true}
            src={location.video.url}
            show={idx === selectedLocationIndex}
          />
        ))}
      </VideoContainer>

      <Box p={[2, 5]}>
        <ConstrainedWidthContainer>
          <LocationContainer py={[2, 5]} px={[2, 4, 5]}>
            <SelectorGroup
              items={selectorItems}
              selectedIndex={selectedLocationIndex}
            />
            <Box mx="auto" my={5} pt={3} width={12 / 12} textAlign="center">
              <SubHeadline>
                {data.contentstackCompanyPage.address_heading}
              </SubHeadline>
              <Paragraph>
                {
                  data.contentstackCompanyPage.locations[selectedLocationIndex]
                    .address
                }
              </Paragraph>
            </Box>

            <Box textAlign="center">
              <a href={data.contentstackCompanyPage.location_button_link_url}>
                <Button>
                  {data.contentstackCompanyPage.location_button_link_text}
                </Button>
              </a>
            </Box>
          </LocationContainer>
        </ConstrainedWidthContainer>
      </Box>
    </Box>
  )
}

export const Locations = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackCompanyPage {
          address_heading
          locations {
            name
            address
            video {
              url
            }
          }
          location_button_link_text
          location_button_link_url
        }
      }
    `}
    render={data => <LocationsWithoutData data={data} {...props} />}
  />
)
