import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import YapstoneLogo from '../../images/Yapstone_Logo.png'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { EyeBrowText, LinkText } from '../atoms'
import { CardShadowed, ContactButton } from '../molecules'
import { HamburgerMenu } from './'

const Container = styled(Box)`
  background: ${props => props.theme.colors.white};
`

const HamburgerContainer = styled.div`
  ${Mq.md} {
    display: none;
  }
`

const Logo = styled.img`
  width: 6rem;
  display: block;

  ${Mq.md} {
    width: 9rem;
  }
`

const LinksContainer = styled(Box)`
  display: none;

  ${Mq.md} {
    display: block;
  }
`

const ListItem = styled.li`
  display: inline-block;
  margin-right: 1.5rem;
  position: relative;

  ${Mq.lg} {
    margin-right: 2.5rem;
  }

  &:hover .dropdown {
    display: block;
  }
`

const DropdownContainer = styled(CardShadowed)`
  position: absolute;
  display: none;
  width: 16rem;
  padding: 1rem;

  z-index: 2;

  &:hover {
    display: block;
  }
`

const StyledSolutionText = styled(LinkText)`
  cursor: pointer;
`

const StyledEyebrowText = styled(EyeBrowText)`
  color: ${props => props.theme.colors.darkGray};
  margin-bottom: 0.3rem;
  display: block;
`

const StyledContactButton = styled(ContactButton)`
  padding-left: 0.8rem;
  padding-right: 0.8rem;

  ${Mq.xs} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`

const HeaderWithoutData = ({ data }) => {
  const renderListItem = link => {
    return (
      <ListItem key={link.url}>
        {link.external_url ? (
          <a href={link.external_url} target="_blank" rel="noreferrer">
            <LinkText>{link.display_text}</LinkText>
          </a>
        ) : (
          <Link to={link.url}>
            <LinkText>{link.display_text}</LinkText>
          </Link>
        )}
      </ListItem>
    )
  }

  return (
    <header>
      <Container px={[3, 4]} py={3}>
        <Flex alignItems="center">
          <HamburgerContainer>
            <HamburgerMenu />
          </HamburgerContainer>

          <Box flex={['1', '1', '0 0 auto']} ml={['3rem', '3rem', 0]}>
            <Link to="/">
              <Logo src={YapstoneLogo} alt="Yapstone Logo" />
            </Link>
          </Box>

          <LinksContainer flex="1" ml={5}>
            <ul>
              {renderListItem(data.contentstackHeader.navigation_link[0])}

              <ListItem>
                <StyledSolutionText>
                  {data.contentstackHeader.solutions_item_text}
                </StyledSolutionText>

                <DropdownContainer className="dropdown">
                  <StyledEyebrowText>
                    {data.contentstackHeader.solutions_dropdown_vertical_text}
                  </StyledEyebrowText>

                  <Box mb={4}>
                    <Link to={data.contentstackHeader.travel_item_url}>
                      <LinkText>
                        {data.contentstackHeader.travel_item_text}
                      </LinkText>
                    </Link>
                  </Box>

                  <StyledEyebrowText>
                    {data.contentstackHeader.solutions_dropdown_business_text}
                  </StyledEyebrowText>

                  <Box mb={4}>
                    <Link to={data.contentstackHeader.marketplaces_item_url}>
                      <LinkText>
                        {data.contentstackHeader.marketplaces_item_text}
                      </LinkText>
                    </Link>
                  </Box>

                  <StyledEyebrowText>
                    {data.contentstackHeader.solutions_dropdown_international_text}
                  </StyledEyebrowText>

                  <Box>
                    <Link to={data.contentstackHeader.global_scale_item_url}>
                      <LinkText>
                        {data.contentstackHeader.global_scale_item_text}
                      </LinkText>
                    </Link>
                  </Box>

                </DropdownContainer>
              </ListItem>

              {data.contentstackHeader.navigation_link
                .slice(1)
                .map(renderListItem)}
            </ul>
          </LinksContainer>

          <Box flex="0 0 auto">
            <StyledContactButton />
          </Box>
        </Flex>
      </Container>
    </header>
  )
}

export const Header = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackHeader {
          solutions_item_text
          solutions_dropdown_vertical_text
          travel_item_text
          travel_item_url
          solutions_dropdown_business_text
          marketplaces_item_text
          marketplaces_item_url
          solutions_dropdown_international_text
        	global_scale_item_text
        	global_scale_item_url
          navigation_link {
            display_text
            url
            external_url
          }
        }
      }
    `}
    render={data => <HeaderWithoutData data={data} {...props} />}
  />
)
