// polyfill for interaction-observer API for older browsers
import 'intersection-observer'

import React, { useState } from 'react'
import Helmet from 'react-helmet'

import { Box } from '@rebass/emotion'

import { ContactModalContext } from '../../contexts'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { ContactModal, CookiesBanner, Footer, Header } from '../organisms'

const FooterContainer = styled(Box)`
  margin-top: 5rem;

  ${Mq.sm} {
    margin-top: 3rem;
  }
`

export const Layout = ({ children }) => {
  const [showContactModal, setShowContactModal] = useState(false)

  return (
    <ContactModalContext.Provider
      value={{ showContactModal, setShowContactModal }}
    >
      <Helmet>
        <meta
          name="google-site-verification"
          content={process.env.GATSBY_GOOGLE_SITE_VERIFICATION}
        />
      </Helmet>

      <Header />

      <Box mt={[4, '5rem']} pb={[4, 6]}>
        <main>{children}</main>
      </Box>

      <FooterContainer>
        <Footer />
      </FooterContainer>

      <ContactModal
        open={showContactModal}
        onClose={() => setShowContactModal(false)}
      />

      <CookiesBanner />
    </ContactModalContext.Provider>
  )
}
