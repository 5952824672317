import React from 'react'

import { Box } from '@rebass/emotion'

import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'

const StyledContainer = styled(Box)`
  position: relative;
  max-width: ${props => props.theme.maxPageWidth};
  padding-left: 2rem;
  padding-right: 2rem;

  ${Mq.md} {
    margin-left: auto;
    margin-right: auto;
  }
`

export const ConstrainedWidthContainer = ({ children, ...rest }) => (
  <StyledContainer {...rest}>{children}</StyledContainer>
)
