import React from 'react'
import { Mq } from '../../styles/settings/theme'

import { Box } from '@rebass/emotion'

import styled from '../../styles/styled'

const Container = styled(Box)`
  box-shadow: ${props => props.theme.cardBoxShadow};
  border-radius: ${props => props.theme.borderRadius};
  position: relative;
  background: ${props => props.theme.colors.white};
  padding: 1.4rem 1rem;
`

export const CardShadowed = props => <Container {...props} />
