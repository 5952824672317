import { graphql, StaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'

import { Box, Flex } from '@rebass/emotion'

import DotsSvg from '../../images/dots.svg'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { SmallParagraphText, SubHeadline } from '../atoms'
import { ConstrainedWidthContainer, SelectorGroup } from '../molecules'
import { BioModal } from './'

const Container = styled(Flex)`
  overflow: hidden;
  position: relative;
`

const SliderContainer = styled(Box)`
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  display: block;

  ${Mq.lg} {
    display: none;
  }
`

const Slider = styled(Flex)`
  flex-wrap: nowrap;
  transition: ${props => props.theme.basicTransition};
  align-items: start;
  padding-left: 5vw;

  ${Mq.lg} {
    padding-left: 25vw;
  }
`

const Dots = styled(Box)`
  background-image: url('${DotsSvg}');
  position: absolute;
  width: 12rem;
  height: 21rem;
  top: 3rem;
  left: -2rem;
  z-index: -1;
  display: none;

  ${Mq.lg} {
    display: block;
  }
`

const MemberContainer = styled(Box)`
  box-shadow: none;
  border: none;
  background: none;
  display: block;
  text-align: left;
  padding: 0;
  flex: 0 0 auto;
  width: 16.4375rem;
  margin-right: 1rem;
  outline: 0;
  cursor: pointer;

  &:last-child {
    padding-right: 25px;
  }
`

const MemberGridContainer = styled(Box)`
  text-align: center;
  display: none;
  font-size: 0;

  ${Mq.lg} {
    display: block;
  }
`

const MemberGridItem = styled(MemberContainer)`
  display: inline-block;
  height: 29.375rem;
  margin-right: 0.65rem;
  margin-left: 0.65rem;
  vertical-align: top;

  &:last-child {
    padding-right: 0;
  }
`

const TeamSubHeadline = styled(SubHeadline)`
  margin-bottom: 0.5rem;
`

const Position = styled(SmallParagraphText)`
  color: ${props => props.theme.colors.darkGray};
`

const MemberImage = styled.img`
  height: 20.625rem;
  width: 100%;
  border-radius: ${props => props.theme.cardBorderRadius};
  margin-bottom: 0.5rem;
  box-shadow: 0px 15px 25px rgba(54, 69, 79, 0.15);
  object-fit: cover;
`

const TeamWithoutData = ({ data }) => {
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [selectedPersonIndex, setSelectedPersonIndex] = useState(null as number)
  const [selectedPerson, setSelectedPerson] = useState(null as any)

  useEffect(() => {
    setSelectedPerson(
      data.contentstackCompanyPage.team_groups[selectedGroupIndex].members[
        selectedPersonIndex
      ],
    )
  }, [selectedPersonIndex, selectedGroupIndex])

  const selectorItems = data.contentstackCompanyPage.team_groups.map(
    (group, idx) => ({
      text: group.name,
      onClick: () => setSelectedGroupIndex(idx),
    }),
  )

  return (
    <Container py={5} my={5} flexWrap="wrap">
      <Dots />
      <ConstrainedWidthContainer width={[1]}>
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box width={[1, 1, 'auto']} mb={[5, 0]}>
            <TeamSubHeadline>
              {data.contentstackCompanyPage.team_section_heading}
            </TeamSubHeadline>
          </Box>
          <Box width={[1, 1, 'auto']}>
            <SelectorGroup
              items={selectorItems}
              selectedIndex={selectedGroupIndex}
            />
          </Box>
        </Flex>
      </ConstrainedWidthContainer>

      <SliderContainer py={5}>
        <Slider>
          {data.contentstackCompanyPage.team_groups[
            selectedGroupIndex
          ].members.map((member, idx) => (
            <MemberContainer
              key={idx}
              onClick={() => {
                setSelectedPersonIndex(idx)
                setShowModal(true)
              }}
            >
              <MemberImage src={member.photo.url} alt={member.name} />
              <TeamSubHeadline>{member.name}</TeamSubHeadline>
              <Position>{member.position}</Position>
            </MemberContainer>
          ))}
        </Slider>
      </SliderContainer>

      <ConstrainedWidthContainer width={[1]}>
        <MemberGridContainer pt={5}>
          {data.contentstackCompanyPage.team_groups[
            selectedGroupIndex
          ].members.map((member, idx) => (
            <MemberGridItem
              key={idx}
              onClick={() => {
                setSelectedPersonIndex(idx)
                setShowModal(true)
              }}
            >
              <MemberImage src={member.photo.url} alt={member.name} />
              <TeamSubHeadline>{member.name}</TeamSubHeadline>
              <Position>{member.position}</Position>
            </MemberGridItem>
          ))}
        </MemberGridContainer>
      </ConstrainedWidthContainer>

      {selectedPerson ? (
        <BioModal
          open={showModal}
          onClose={() => setShowModal(false)}
          person={{
            name: selectedPerson.name,
            position: selectedPerson.position,
            biography: selectedPerson.biography,
            photoUrl: selectedPerson.photo.url,
            linkedinUrl: selectedPerson.linkedin_url,
            twitterUrl: selectedPerson.twitter_url,
          }}
        />
      ) : null}
    </Container>
  )
}

export const Team = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackCompanyPage {
          team_section_heading
          team_groups {
            name
            members {
              name
              position
              photo {
                url
              }
              biography
              linkedin_url
              twitter_url
            }
          }
        }
      }
    `}
    render={data => <TeamWithoutData data={data} {...props} />}
  />
)
