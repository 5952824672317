import { Link } from 'gatsby'
import React from 'react'

import { Box, Image } from '@rebass/emotion'

import ChevronRightSvg from '../../images/chevron-right.svg'
import styled from '../../styles/styled'

interface IProps extends IContainerProps {
  title: string
}

interface IContainerProps {
  imageUrl: string
}

const Container = styled(Box)<IContainerProps>`
  background: no-repeat center url("${props => props.imageUrl}");
  background-size: cover;
  min-height: 20rem;
  border-radius: ${props => props.theme.borderRadius};
  position: relative;
  overflow: hidden;
`

const LinkBox = styled(Box)<IContainerProps>`
  background: linear-gradient(
    90deg,
    rgb(1, 145, 241, 0.5) 0%,
    rgb(108, 48, 128, 0.5) 100%
  );
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 1rem;
`

const ChevronRight = styled(Image)`
  padding-left: 0.8rem;
`

const Title = styled.h3`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontsizes.xs};
  line-height: 2;
  display: inline;
`

export const ImageCard = ({ title, imageUrl }: IProps) => (
  <Container imageUrl={imageUrl}>
    <LinkBox>
      <Title>{title}</Title>
      <ChevronRight src={ChevronRightSvg} alt="Right Arrow" />
    </LinkBox>
  </Container>
)
