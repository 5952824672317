import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import { Mq, ThemeColors } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { Paragraph, SubHeadline } from '../atoms'

interface IProps extends IHeadlineProps {
  headline: string
  description: string
  icon?: {
    url: string
    altText: string
  }
}

interface IHeadlineProps {
  // TODO: make this more robust to only allow theme color strings
  headlineColor: ThemeColors
}

const StyledHeadline = styled(SubHeadline)<IHeadlineProps>`
  color: ${props => props.theme.colors[props.headlineColor]};
`

const IconContainer = styled(Box)`
  display: none;

  ${Mq.sm} {
    display: block;
  }
`
const Text = styled(Paragraph)`
  margin-top: -15px;
`

export const ProductSectionItem = ({
  headline,
  description,
  icon,
  headlineColor,
}: IProps) => {
  return (
    <Flex key={headline}>
      {icon ? (
        <IconContainer flex={['0 0 auto']} mx={['auto']} mr={2} mt={[0, 1]}>
          <img src={icon.url} alt={icon.altText} />
        </IconContainer>
      ) : null}

      <Box>
        <StyledHeadline headlineColor={headlineColor}>
          {headline}
        </StyledHeadline>
        <Text>{description}</Text>
      </Box>
    </Flex>
  )
}
