import { ErrorMessage, Field, Form, Formik } from 'formik'
import { graphql, StaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { Box } from '@rebass/emotion'

import { useScript } from '../../hooks'
import ContactGraphic from '../../images/Contact_Graphic_Success.svg'
import styled from '../../styles/styled'
import {
  Button,
  Headline,
  Input,
  InputError,
  Label,
  Paragraph,
  TextArea,
} from '../atoms'
import { ConstrainedWidthContainer, Modal } from '../molecules'

interface IProps {
  data: any
  open: boolean
  onClose: () => void
}

const Container = styled(Box)`
  text-align: center;
`
const ButtonContainer = styled(Box)`
  position: absolute;
  top: 1rem;
  right: 2rem;
`

const MessageContainer = styled(Field)`
  background: ${props => props.theme.colors.lightBlue};
  font-size: ${props => props.theme.fontsizes.xs};
  height: 10rem;
  line-height: 1.5;
  font-weight: 500;
  border: none;
`

const FieldContainer = styled(Field)`
  background: ${props => props.theme.colors.lightBlue};
  font-size: ${props => props.theme.fontsizes.xs};
  font-weight: 500;
  border: none;
`

const ContactImage = styled.img`
  width: 15rem;
`

const SubscriptionSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
})

const ContactForm = ({ marketoForm, setSubmitSuccess }) => {
  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        company: '',
        message: '',
      }}
      validationSchema={SubscriptionSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)

        marketoForm.onSuccess(() => {
          setSubmitSuccess(true)
          setSubmitting(false)
          return false
        })

        marketoForm.setValues({
          FirstName: values.firstName,
          LastName: values.lastName,
          Email: values.email,
          Company: values.company,
          Message: values.message,
        })

        marketoForm.submit()
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box>
            <Headline>Get In touch with us</Headline>
            <Box mb={3}>
              <Label htmlFor="firstName">
                <FieldContainer
                  as={Input}
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                />
              </Label>
              <ErrorMessage component={InputError} name="firstName" />
            </Box>

            <Box mb={3}>
              <Label htmlFor="lastName">
                <FieldContainer
                  as={Input}
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                />
              </Label>
              <ErrorMessage component={InputError} name="lastName" />
            </Box>

            <Box mb={3}>
              <Label htmlFor="email">
                <FieldContainer
                  as={Input}
                  name="email"
                  type="email"
                  placeholder="email@yapstone.com"
                />
              </Label>
              <ErrorMessage component={InputError} name="email" />
            </Box>

            <Box mb={3}>
              <Label htmlFor="company">
                <FieldContainer
                  as={Input}
                  name="company"
                  type="text"
                  placeholder="Your Company"
                />
              </Label>
              <ErrorMessage component={InputError} name="company" />
            </Box>

            <Box mb={4}>
              <Label htmlFor="message">
                <MessageContainer
                  as={TextArea}
                  name="message"
                  type="text"
                  placeholder="Write your message here..."
                />
              </Label>
              <ErrorMessage component={InputError} name="message" />
            </Box>

            <Box textAlign="center">
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Sending' : 'Send Message'}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export const ContactModalWithoutData = ({ data, open, onClose }: IProps) => {
  const [marketoForm, setMarketoForm] = useState(null)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  // Marketo Form loading, we will hide and use our own UI
  // We will submit this programatically
  const [scriptLoaded] = useScript(
    '//app-sj27.marketo.com/js/forms2/js/forms2.min.js',
  )

  useEffect(() => {
    if (scriptLoaded) {
      // @ts-ignore (mrktoForms2 loaded from script tag above)
      MktoForms2.loadForm(
        '//app-sj27.marketo.com',
        '667-CDO-976',
        1201,
        form => {
          setMarketoForm(form)
        },
      )
    }
  }, [scriptLoaded])

  const renderFormArea = () => {
    if (!marketoForm) {
      return (
        <Container mt={5}>
          <Paragraph>Loading ...</Paragraph>
        </Container>
      )
    } else if (submitSuccess) {
      return (
        <Container>
          <Box mb={3}>
            <ContactImage src={ContactGraphic} alt="Contact Us Graphic" />
          </Box>

          <Headline>
            {data.contentstackContactUsModal.submit_success_headline}
          </Headline>

          <Box mb={4}>
            <Paragraph>{data.contentstackContactUsModal.description}</Paragraph>
          </Box>

          <Button onClick={onClose}>Close</Button>
        </Container>
      )
    } else {
      return (
        <ContactForm
          marketoForm={marketoForm}
          setSubmitSuccess={setSubmitSuccess}
        />
      )
    }
  }

  return (
    <>
      <form id="mktoForm_1201" style={{ display: 'none' }} />

      <Modal
        isOpen={open}
        onRequestClose={onClose}
        contentLabel="Biography modal"
      >
        <ButtonContainer>
          <Button onClick={onClose}>Close</Button>
        </ButtonContainer>

        <ConstrainedWidthContainer pt={['5rem']} pb={[4]}>
          {renderFormArea()}
        </ConstrainedWidthContainer>
      </Modal>
    </>
  )
}

export const ContactModal = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackContactUsModal {
          submit_success_headline
          description
        }
      }
    `}
    render={data => <ContactModalWithoutData data={data} {...props} />}
  />
)
