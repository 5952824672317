import { graphql, Link, StaticQuery } from 'gatsby'
import React, { useState } from 'react'

import { Box } from '@rebass/emotion'

import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { Button, EyeBrowText, LinkText } from '../atoms'
import { Modal } from '../molecules'

const MenuButton = styled(Button)`
  ${Mq.lg} {
    display: none;
  }
`

const LinkContainer = styled(Box)`
  a[aria-current='page'] {
    color: ${props => props.theme.colors.darkBlue};
    text-decoration: underline;

    span {
      color: ${props => props.theme.colors.darkBlue};
    }
  }
`

const NonMobileContainer = styled(Box)`
  display: none;

  ${Mq.lg} {
    display: block;
  }
`

const SectionHeader = styled(EyeBrowText)`
  color: ${props => props.theme.colors.black};
`

const StyledLinkText = styled(LinkText)`
  color: ${props => props.theme.colors.gray1};
`

const ButtonContainer = styled(Box)`
  text-align: right;
`

const LegalCenterNavWithoutData = ({ data }) => {
  const [showModal, setShowModal] = useState(false)

  const navItems = data.contentstackLegalCenterSections.legal_center_sections.map(
    section => (
      <Box key={section.headline}>
        <Box mt={4}>
          <SectionHeader>{section.headline}</SectionHeader>
        </Box>

        <ul>
          {section.legal_center_pages.map(page => (
            <li key={page.url}>
              <Box my={4} ml={3}>
                <LinkContainer>
                  <Link to={page.url}>
                    <StyledLinkText>{page.title}</StyledLinkText>
                  </Link>
                </LinkContainer>
              </Box>
            </li>
          ))}
        </ul>
      </Box>
    ),
  )

  return (
    <Box mb={4}>
      <MenuButton width={[1]} onClick={() => setShowModal(true)}>
        {data.contentstackLegalCenterSections.menu_button_text}
      </MenuButton>

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Biography modal"
      >
        <Box p={[4]}>
          <ButtonContainer>
            <Button onClick={() => setShowModal(false)}>Close</Button>
          </ButtonContainer>

          {navItems}
        </Box>
      </Modal>

      <NonMobileContainer>{navItems}</NonMobileContainer>
    </Box>
  )
}

export const LegalCenterNav = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackLegalCenterSections {
          menu_button_text
          legal_center_sections {
            headline
            legal_center_pages {
              title
              url
            }
          }
        }
      }
    `}
    render={data => <LegalCenterNavWithoutData data={data} {...props} />}
  />
)
