import React from 'react'

import { Box } from '@rebass/emotion'

import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { SelectorTextButton } from '../atoms'

interface IItem {
  text: string
  onClick: () => void
}

interface IProps {
  items: IItem[]
  selectedIndex: number
}

const ItemContainer = styled(Box)`
  margin-bottom: 1rem;

  &:last-child {
    margin-right: 0;
  }

  ${Mq.sm} {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 2rem;
  }

  ${Mq.md} {
    margin-right: 2.5rem;
  }
`

export const SelectorGroup = ({ items, selectedIndex }: IProps) => (
  <Box>
    {items.map((item, idx) => (
      <ItemContainer key={idx}>
        <SelectorTextButton
          text={item.text}
          onClick={item.onClick}
          selected={idx === selectedIndex}
        />
      </ItemContainer>
    ))}
  </Box>
)
