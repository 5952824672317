import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import { Headline, Paragraph, SubHeadline } from '../atoms'
import { ConstrainedWidthContainer, ContactButton } from '../molecules'

const GetInTouchWithoutData = ({ data }) => (
  <Box py={3}>
    <ConstrainedWidthContainer>
      <Flex
        flexWrap="wrap"
        // my={[2, 5]}
        pt={[4, 6]}
        pb={[3, 5]}
        // py={[3, 5]}
        px={[0, 0, 0, 0, 5]}
        alignItems="center"
        justifyContent="center"
      >
        <Box width={[1, 1, 1, 6 / 12]}>
          <Headline>{data.contentstackContact.headline}</Headline>
          <Paragraph>{data.contentstackContact.description}</Paragraph>
        </Box>
        <Box
          width={[1, 1, 1, 4 / 12]}
          mt={[3, 3, 3, 3, 0]}
          textAlign={['left', 'center']}
        >
          <ContactButton />
        </Box>
      </Flex>
    </ConstrainedWidthContainer>
  </Box>
)

export const GetInTouch = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackContact {
          headline
          description
        }
      }
    `}
    render={data => <GetInTouchWithoutData data={data} {...props} />}
  />
)
