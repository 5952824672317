import { Box } from '@rebass/emotion'
import lottie from 'lottie-web'
import React, { useEffect, useRef } from 'react'
import styled from '../../styles/styled'

interface IProps {
  jsonObject: object
  loop: boolean
  autoplay: boolean
}

const SVGContainer = styled(Box)`
  height: 100%;
  width: 100%;
`

export const SVGDropper = ({
  jsonObject,
  loop = false,
  autoplay = false,
}: IProps) => {
  const el = useRef(null)

  useEffect(() => {
    if (!el) {
      return
    }

    const options: any = {
      container: el.current,
      renderer: 'svg',
      segments: false,
      loop,
      autoplay,
      animationData: jsonObject,
    }

    lottie.loadAnimation(options)
  }, [el])

  return <SVGContainer ref={el} />
}
