import { rgba } from 'polished'

import styled from '../../styles/styled'

export const Input = styled.input`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.7rem;
  width: 100%;
  border-radius: 5px;
  border-style: solid;
  border-color: ${props => rgba(props.theme.colors.black, 0.3)};
`
