import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'

import { Box } from '@rebass/emotion'

import DotsSvg from '../../images/dots.svg'
import GrowthGraphicImage from '../../images/Icon_Home_Growth 1.png'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { Button, EyeBrowText, Headline, Paragraph } from '../atoms'
import { CardShadowed } from '../molecules'

const Dots = styled(Box)`
  background-image: url('${DotsSvg}');
  position: absolute;
  width: 6rem;
  height: 12rem;
  top: 30%;
  left: -4.3rem;
  z-index: -1;
  display: none;

  ${Mq.md} {
    display: block;
  }
`

const GrowthGraphic = styled(Box)`
  background-image: url('${GrowthGraphicImage}');
  position: absolute;
  width: 15rem;
  height: 15rem;
  bottom: -1.2rem;
  right: -1.3rem;
  display: none;
  background-size: contain;
  background-repeat: no-repeat;

  ${Mq.lg} {
    display: block;
  }
`

const GrowthCard = styled(CardShadowed)`
  ${Mq.md} {
    padding: 3rem 5.5rem;
  }
`

const HomeGrowthWithoutData = ({ data }) => (
  <GrowthCard width={[1, 1, 10 / 12]} mx={[0, 'auto']} mb={6}>
    <Dots />
    <GrowthGraphic />

    <Headline>{data.contentstackHomePage.growth_headline}</Headline>

    <Box mb={[4]}>
      <Paragraph>{data.contentstackHomePage.growth_description}</Paragraph>
    </Box>

    <Box textAlign="center">
      <Link to={data.contentstackHomePage.explore_button_url}>
        <Button width={[1, 'auto']}>
          {data.contentstackHomePage.explore_products_button_text}
        </Button>
      </Link>
    </Box>
  </GrowthCard>
)

export const HomeGrowth = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackHomePage {
          explore_button_url
          growth_headline
          growth_description
          explore_products_button_text
        }
      }
    `}
    render={data => <HomeGrowthWithoutData data={data} {...props} />}
  />
)
