import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'

import { Box } from '@rebass/emotion'

import DotsSvg from '../../images/dots.svg'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { Headline, SubHeadline } from '../atoms'
import { ConstrainedWidthContainer, ImageCard } from '../molecules'

const Container = styled(Box)`
  position: relative;
`

const NewsPanelsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem 1rem;
  margin-top: 1.8rem;

  ${Mq.sm} {
    // TODO: add css grid stuff to get first panel to fill 2 column width
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem 2rem;

    a:first-of-type {
      grid-column: 1 / 3;
    }
  }
`
const Dots = styled(Box)`
  background-image: url('${DotsSvg}');
  position: absolute;
  width: 12rem;
  height: 21rem;
  left: -2rem;
  top: -5rem;
  z-index: -1;
  display: none;

  ${Mq.xxl} {
    display: block;
  }
`

const NewsSubHeadline = styled(SubHeadline)`
  color: ${props => props.theme.colors.darkGray};
`

const CompanyNewsWithoutData = ({ data }) => (
  <Container>
    <Dots />

    <ConstrainedWidthContainer p={3} mb={5}>
      <Headline>{data.contentstackCompanyPage.news_section_heading}</Headline>
      <NewsSubHeadline>
        {data.contentstackCompanyPage.news_section_subheading}
      </NewsSubHeadline>

      <NewsPanelsContainer>
        {data.contentstackCompanyPage.news_panels.map(panel => (
          <Link key={panel.link} to={panel.link}>
            <ImageCard imageUrl={panel.image.url} title={panel.title} />
          </Link>
        ))}
      </NewsPanelsContainer>
    </ConstrainedWidthContainer>
  </Container>
)

export const CompanyNews = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackCompanyPage {
          news_section_heading
          news_section_subheading
          news_panels {
            title
            link
            image {
              url
            }
          }
        }
      }
    `}
    render={data => <CompanyNewsWithoutData data={data} {...props} />}
  />
)
