import React from 'react'

import { Flex } from '@rebass/emotion'

import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'

const Container = styled(Flex)`
  color: initial;
  align-items: center;
  justify-content: flex-start;

  ${Mq.sm} {
    min-height: 60vh;
    background: linear-gradient(47.19deg, rgba(54, 69, 79, 0.8) 35.94%, rgba(54, 69, 79, 0) 100%), no-repeat center url("${props =>
      props.imageUrl}");
    background-size: cover;
    color: ${props => props.theme.colors.white};
  }
`

export const GradientHeroContainer = ({ children, imageUrl }) => (
  <Container imageUrl={imageUrl} py={[2, 6]}>
    {children}
  </Container>
)
