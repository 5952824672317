import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

import { Box } from '@rebass/emotion'

import { SVGDropper } from '../../components/atoms/SVGDropper'
import MoreTravelerSVG from '../../images/lottie/Travel_Graphic_GetMoreTravelers.json'
import GetMoreImageMobile from '../../images/Travel_Graphic_GetMoreTravelers_Mobile.svg'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { Paragraph, SubHeadline } from '../atoms'
import { ConstrainedWidthContainer } from '../molecules'

const ImageContainer = styled(Box)`
  display: none;

  ${Mq.md} {
    display: block;
    transform: translateY(-9rem);
    height: 35rem;
  }
`

const MobileImage = styled.img`
  ${Mq.md} {
    display: none;
  }
`

const TravelGetMoreWithoutData = ({ data }) => (
  <Box mt={[4, 5, 6]} pt={3}>
    <MobileImage src={GetMoreImageMobile} alt="Get More Graphic" />

    <ConstrainedWidthContainer>
      <Box width={[1, 1, 6 / 12, 5 / 12]} mx={[0, 0, 'auto']}>
        <SubHeadline>
          {data.contentstackTravelPage.get_more_headline}
        </SubHeadline>
        <Box mb={[2]}>
          <Paragraph>
            {data.contentstackTravelPage.get_more_description}
          </Paragraph>
        </Box>
      </Box>
      <ImageContainer>
        <SVGDropper jsonObject={MoreTravelerSVG} loop={true} autoplay={true} />
      </ImageContainer>
    </ConstrainedWidthContainer>
  </Box>
)

export const TravelGetMore = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackTravelPage {
          get_more_headline
          get_more_description
        }
      }
    `}
    render={data => <TravelGetMoreWithoutData data={data} {...props} />}
  />
)
