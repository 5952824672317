import { format } from 'date-fns'
import React from 'react'

import { Box } from '@rebass/emotion'

import styled from '../../styles/styled'

interface IProps extends IImageContainerProps {
  title: string
  date: Date
}

interface IImageContainerProps {
  imageUrl: string
}

const Container = styled(Box)``

const ImageContainer = styled(Box)<IImageContainerProps>`
  background: no-repeat center url("${props => props.imageUrl}");
  background-size: cover;
  padding: 1rem;
  min-height: 15rem;
  border-radius: ${props => props.theme.cardBorderRadius};
`

const Date = styled.span`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.fontsizes.xxs};
`

const Title = styled.h2`
  font-size: ${props => props.theme.fontsizes.xs};
  font-weight: 500;
`

export const MediaCard = ({ title, date, imageUrl }: IProps) => (
  <Container>
    <ImageContainer imageUrl={imageUrl} mb={2} />

    <Box mb={2}>
      <Date>{format(date, 'MMMM dd, yyyy')}</Date>
    </Box>

    <Title>{title}</Title>
  </Container>
)
