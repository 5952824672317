import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'

import { Box } from '@rebass/emotion'

import { ScrollSVGAnimator } from '../../components/atoms/'
import BottomDivider from '../../images/bottom-divider.svg'
import DotsSvg from '../../images/dots.svg'
import PlatformImageMobile from '../../images/Home_Graphic_OnePlatform_Mobile.svg'
import OnePlatform from '../../images/lottie/Home_Graphic_OnePlatform.json'
import TopDivider from '../../images/top-divider.svg'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { Button, EyeBrowText, Headline, Paragraph } from '../atoms'
import { ConstrainedWidthContainer } from '../molecules'

const Container = styled(Box)`
  background-color: ${props => props.theme.colors.white};
  ${Mq.sm} {
    background-color: ${props => props.theme.colors.lightBlue};
  }
`

const PlatformImageContainer = styled(Box)`
  min-height: 20rem;
  display: none;
  z-index: 3;
  position: relative;

  ${Mq.sm} {
    display: block;
    min-height: 25rem;
  }
`

const MobilePlatformImage = styled.img`
  ${Mq.sm} {
    display: none;
  }
`
const Divider = styled.img`
  width: 100%;
  display: none;

  ${Mq.sm} {
    display: block;
  }
`
const BDivider = styled.img`
  position: relative;
  z-index: 0;
  bottom: -6px;
  width: 100%;
  display: none;

  ${Mq.sm} {
    display: block;
  }
`
const Dots = styled(Box)`
  background-image: url('${DotsSvg}');
  position: absolute;
  width: 12rem;
  height: 18.1rem;
  top: 125%;
  left: -10.3rem;
  z-index: 0;
  display: none;
  transform: translateY(-50%);

  ${Mq.sm} {
    display: block;
  }
`

const Dots2 = styled(Box)`
  background-image: url('${DotsSvg}');
  position: absolute;
  width: 12rem;
  height: 18.1rem;
  top: -20%;
  right: -10.3rem;
  z-index: 0;
  display: none;
  transform: translateY(50%);

  ${Mq.sm} {
    display: block;
  }
`

const HomePlatformWithoutData = ({ data }) => (
  <Container mt={'-8%'}>
    <Divider src={TopDivider} alt="divider" />

    <ConstrainedWidthContainer>
      <MobilePlatformImage
        src={PlatformImageMobile}
        alt="One Platform Graphic"
      />

      <Box
        pt={[3, 5, 5, 0]}
        px={[0, 4, 5]}
        width={[1, 1, 10 / 12]}
        mx={[0, 0, 'auto']}
      >
        <Box mb={[3]}>
          <Dots />
          {/* <Dots2 /> */}
        </Box>

        <Headline>{data.contentstackHomePage.platform_headline}</Headline>
        <Box mb={[4]}>
          <Paragraph>
            {data.contentstackHomePage.platform_description}
          </Paragraph>
        </Box>

        <Link to={data.contentstackHomePage.explore_button_url}>
          <Button width={[1, 'auto']}>
            {data.contentstackHomePage.platform_learn_more_button_text}
          </Button>
        </Link>
      </Box>
      <Box px={[0, 5]}>
        <PlatformImageContainer>
          <ScrollSVGAnimator thresholdPercent={0.3} jsonObject={OnePlatform} />
        </PlatformImageContainer>
      </Box>
    </ConstrainedWidthContainer>

    <Box mt={'-8%'}>
      <BDivider src={BottomDivider} />
    </Box>
  </Container>
)

export const HomePlatform = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackHomePage {
          platform_headline
          platform_description
          platform_learn_more_button_text
          explore_button_url
        }
      }
    `}
    render={data => <HomePlatformWithoutData data={data} {...props} />}
  />
)
