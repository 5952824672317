import { graphql, StaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'

import { Box, Flex } from '@rebass/emotion'

import styled from '../../styles/styled'
import { Button, Paragraph, LinkText } from '../atoms'
import { CardShadowed, ConstrainedWidthContainer } from '../molecules'

interface IContainerProps {
  show: boolean
}

const Container = styled(Box)<IContainerProps>`
  display: ${props => (props.show ? 'block' : 'none')};
  position: fixed;
  bottom: 2rem;
  left: 0;
  width: 100%;
  z-index: 99;
`

const COOKIE_BANNER_KEY = 'cookie_banner_seen'

const CookiesBannerWithoutData = ({ data }) => {
  const [show, setShow] = useState(undefined)

  useEffect(() => {
    setShow(!Boolean(localStorage.getItem(COOKIE_BANNER_KEY)))
  }, [])

  const closeBanner = () => {
    localStorage.setItem(COOKIE_BANNER_KEY, 'true')
    setShow(false)
  }

  return (
    <Container show={show}>
      <ConstrainedWidthContainer>
        <CardShadowed py={[5, 4]} px={[3, 4, 5]}>
          <Flex flexWrap="wrap" alignItems="center">
            <Box flex={['0 0 100%', '1']} mr={[0, 3, 5, 6]}>
              <Paragraph>
                {data.contentstackCookiesConsentBanner.us_description}
                &nbsp;
                <a href={(data.contentstackCookiesConsentBanner.locale === "en-us") ? data.contentstackCookiesConsentBanner.privacy_link_us.href : data.contentstackCookiesConsentBanner.privacy_link_eu.href} target="_blank">
                  <LinkText>{data.contentstackCookiesConsentBanner.privacy_link_us.title}</LinkText>
                </a>
              </Paragraph>
            </Box>
            <Box flex={['0 0 100%', '0 0 auto']} mb={[0, 4]}>
              <Button onClick={closeBanner} width={[1, 'auto']}>
                {data.contentstackCookiesConsentBanner.agree_button_text}
              </Button>
            </Box>
          </Flex>
        </CardShadowed>
      </ConstrainedWidthContainer>
    </Container>
  )
}

export const CookiesBanner = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackCookiesConsentBanner {
					us_description
      		privacy_link_us {
            title
            href
          }
          europe_description
          privacy_link_eu {
            title
            href
          }
          agree_button_text
          locale
        }
      }
    `}
    render={data => <CookiesBannerWithoutData data={data} {...props} />}
  />
)
