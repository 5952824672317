import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import lottie from 'lottie-web'
import React, { useEffect, useRef, useState } from 'react'

interface IProps {
  jsonObject: object
  thresholdPercent: number
}

export const ScrollSVGAnimator = ({ jsonObject, thresholdPercent }: IProps) => {
  const [anim, setAnim] = useState(null)
  const [elPosY, setElPosY] = useState(null)

  const el = useRef(null)

  useEffect(() => {
    if (!el) {
      return
    }

    const options: any = {
      container: el.current,
      renderer: 'svg',
      segments: false,
      loop: false,
      autoplay: false,
      animationData: jsonObject,
    }

    setAnim(lottie.loadAnimation(options))
  }, [el])

  useEffect(() => {
    if (!anim) {
      return
    }

    // How far up the viewport do you want the animation to start
    // 0 = where the bottom of the viewport touches the top of the SVG's container
    // .5 would start the animation when the top of the SVG is in the middle of the viewport
    const threshold = window.innerHeight * thresholdPercent

    if (elPosY > 0 && elPosY < window.innerHeight - threshold) {
      // we are in the viewport
      const progress = 1 - elPosY / (window.innerHeight - threshold)
      const stopFrame = Math.round((anim.totalFrames - 1) * progress)
      anim.goToAndStop(stopFrame, true)
    } else if (elPosY <= 0) {
      anim.goToAndStop(anim.totalFrames - 1, true)
    } else {
      anim.goToAndStop(1, true)
    }
  }, [elPosY, anim, thresholdPercent])

  useScrollPosition(
    ({ currPos }) => {
      setElPosY(currPos.y)
    }, // effect
    null, // deps
    el, // element
    false, // useWindow
    30, // Throttle wait in ms
  )

  return <div ref={el} />
}
