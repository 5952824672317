import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { Link } from 'react-scroll'

import { Box, Flex } from '@rebass/emotion'

import OnboardingIcon from '../../images/onboarding_icon.svg'
import PayInIcon from '../../images/payin_icon.svg'
import PayOutIcon from '../../images/payout_icon.svg'
import ReportingIcon from '../../images/reporting_icon.svg'
import RiskIcon from '../../images/risk_icon.svg'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { EyeBrowText } from '../atoms'

interface IProps {
  currentNavItemId: keyof typeof PRODUCT_SECTION_IDS
}

interface ILogoImgProps {
  active: boolean
}

export const PRODUCT_SECTION_IDS = {
  pay_in: 'pay_in',
  pay_out: 'pay_out',
  onboarding: 'onboarding',
  risk: 'risk',
  reporting: 'reporting',
}

const ItemContainer = styled(Box)`
  text-align: center;

  &:hover,
  &:focus {
    img {
      filter: none;
    }

    span.nav-text {
      color: ${props => props.theme.colors.black};
    }
  }
`

const IconContainer = styled(Flex)`
  box-shadow: 0px 15px 25px rgba(54, 69, 79, 0.15);
  background: white;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
`

const Overflow = styled(Flex)`
  z-index: 9;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background: rgba(255, 255, 255, 0.95);
  display: none;
  margin: 0 auto;

  ${Mq.md} {
    display: flex;
    padding: 1rem 4rem;
  }
  ${Mq.lg} {
    padding: 1rem 5rem;
  }
  ${Mq.xl} {
    padding: 1rem 10rem;
  }
`

const LogoImg = styled.img<ILogoImgProps>`
  ${props => (props.active ? '' : 'filter: grayscale(1);')}
`

const ProductsNavWithoutData = ({ data, currentNavItemId }) => {
  const navItemsData = [
    {
      icon: PayInIcon,
      iconAlt: 'Pay In Icon',
      text: data.contentstackProductsPage.pay_in_nav_text,
      anchorId: PRODUCT_SECTION_IDS.pay_in,
    },
    {
      icon: PayOutIcon,
      iconAlt: 'Pay Out Icon',
      text: data.contentstackProductsPage.pay_out_nav_text,
      anchorId: PRODUCT_SECTION_IDS.pay_out,
    },
    {
      icon: OnboardingIcon,
      iconAlt: 'Onboarding Icon',
      text: data.contentstackProductsPage.onboarding_nav_text,
      anchorId: PRODUCT_SECTION_IDS.onboarding,
    },
    {
      icon: RiskIcon,
      iconAlt: 'Risk Icon',
      text: data.contentstackProductsPage.risk_nav_text,
      anchorId: PRODUCT_SECTION_IDS.risk,
    },
    {
      icon: ReportingIcon,
      iconAlt: 'Reporting Icon',
      text: data.contentstackProductsPage.reporting_nav_text,
      anchorId: PRODUCT_SECTION_IDS.reporting,
    },
  ]

  return (
    <Overflow>
      {navItemsData.map(item => (
        <ItemContainer key={item.anchorId} flex={[1]}>
          <Link to={item.anchorId} smooth={true} duration={750}>
            <IconContainer>
              <LogoImg
                src={item.icon}
                alt={item.iconAlt}
                active={item.anchorId === currentNavItemId}
              />
            </IconContainer>

            <EyeBrowText
              color={item.anchorId === currentNavItemId ? 'black' : 'gray1'}
              className="nav-text"
            >
              {item.text}
            </EyeBrowText>
          </Link>
        </ItemContainer>
      ))}
    </Overflow>
  )
}

export const ProductsNav = (props: IProps) => (
  <StaticQuery
    query={graphql`
      query {
        contentstackProductsPage {
          marketplaces_nav_text
          pay_in_nav_text
          pay_out_nav_text
          onboarding_nav_text
          risk_nav_text
          reporting_nav_text
        }
      }
    `}
    render={data => <ProductsNavWithoutData data={data} {...props} />}
  />
)
