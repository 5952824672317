import { graphql, StaticQuery } from 'gatsby'
import React, { useState } from 'react'

import { Location } from '@reach/router'
import { Box, Flex } from '@rebass/emotion'

import ShareIconEmail from '../../images/share-icon-email.svg'
import ShareIconFacebook from '../../images/share-icon-facebook.svg'
import ShareIconLinkedin from '../../images/share-icon-linkedin.svg'
import ShareIconTwitter from '../../images/share-icon-twitter.svg'
import { Mq } from '../../styles/settings/theme'
import styled from '../../styles/styled'
import { Button, Headline, Input, LinkText } from '../atoms'
import { ConstrainedWidthContainer, Modal } from '../molecules'

interface IProps {
  data: any
  subjectTitle: string
  path: string
}

const ButtonContainer = styled(Box)`
  position: absolute;
  top: 2rem;
  right: 2rem;
`

const HeadlineContainer = styled(Box)`
  text-align: center;
`

const ShareLink = styled.a`
  text-align: center;
  display: block;
`

const ShareIconContainer = styled(Box)`
  position: relative;
  height: 4rem;
  width: 4rem;
  border-radius: 4rem;

  ${Mq.md} {
    height: 5rem;
    width: 5rem;
    border-radius: 5rem;
  }
  &.facebook {
    background: #3b5998;
  }
  &.twitter {
    background: #38a1f3;
  }
  &.linkedin {
    background: #0077b5;
  }
  &.email {
    background: ${props => props.theme.colors.aqua};
  }
`

const ShareIconImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ShareLinkInput = styled(Input)`
  background: ${props => props.theme.colors.lightBlue};
  font-size: ${props => props.theme.fontsizes.xs};
  font-weight: 500;
  border: none;
`

const ShareButtonAndModalWithoutData = ({
  data,
  subjectTitle,
  path,
  ...rest
}: IProps) => {
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const currentUrl =
    typeof window !== 'undefined' &&
    `${window.location.protocol}//${window.location.host}${path}`

  const socialSitesData = [
    {
      text: 'Facebook',
      href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
        currentUrl,
      )}`,
      icon: ShareIconFacebook,
      className: 'facebook',
      target: '_blank',
    },
    {
      text: 'Twitter',
      href: `https://twitter.com/intent/tweet?url=${encodeURI(currentUrl)}`,
      icon: ShareIconTwitter,
      className: 'twitter',
      target: '_blank',
    },
    {
      text: 'Linkedin',
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`,
      icon: ShareIconLinkedin,
      className: 'linkedin',
      target: '_blank',
    },
    {
      text: 'Email',
      href: `mailto:?subject=${encodeURIComponent(
        subjectTitle,
      )}&body=${encodeURI(currentUrl)}`,
      icon: ShareIconEmail,
      className: 'email',
    },
  ]

  return (
    <>
      <Button {...rest} onClick={openModal}>
        {data.contentstackShareWithOthersButton.title}
      </Button>

      <Modal isOpen={modalOpen} onRequestClose={closeModal}>
        <ButtonContainer>
          <Button onClick={closeModal}>Close</Button>
        </ButtonContainer>

        <ConstrainedWidthContainer>
          <HeadlineContainer mt={[6, 6]} mb={[5, 6]}>
            <Headline>Share</Headline>
          </HeadlineContainer>

          <Flex flexWrap="wrap" my={[2, 4]}>
            {socialSitesData.map(site => (
              <Box mb={[5]} width={[1 / 2, 1 / 4]} key={site.text}>
                <ShareLink
                  rel="noopener noreferrer"
                  target={site.target || null}
                  href={site.href}
                >
                  <ShareIconContainer
                    className={site.className}
                    mb={[3, 4]}
                    mx="auto"
                  >
                    <ShareIconImg src={site.icon} alt={`${site.text} icon`} />
                  </ShareIconContainer>

                  <LinkText>{site.text}</LinkText>
                </ShareLink>
              </Box>
            ))}
          </Flex>

          <Flex my={[3, 5]} justifyContent="center">
            <Box width={[1, 1 / 2]}>
              <ShareLinkInput
                type="text"
                value={currentUrl}
                onClick={event => (event.target as HTMLInputElement).select()}
                readOnly={true}
              />
            </Box>
          </Flex>
        </ConstrainedWidthContainer>
      </Modal>
    </>
  )
}

export const ShareButtonAndModal = props => (
  <StaticQuery
    query={graphql`
      query {
        contentstackShareWithOthersButton {
          title
        }
      }
    `}
    render={data => (
      <Location>
        {({ location }) => (
          <ShareButtonAndModalWithoutData
            data={data}
            path={location.pathname}
            {...props}
          />
        )}
      </Location>
    )}
  />
)
