import React from 'react'

import styled from '../../styles/styled'
import { EyeBrowText } from './Text'

interface IProps extends IStyledTextProps {
  text: string
  onClick: () => void
}

interface IStyledTextProps {
  selected?: boolean
}

const Container = styled.button`
  box-shadow: none;
  border: none;
  background: none;
  padding: 0;
  outline: 0;
  cursor: pointer;
`

export const SelectorTextButton = ({ text, selected, onClick }: IProps) => {
  return (
    <Container onClick={onClick}>
      <EyeBrowText color={selected ? 'darkBlue' : 'darkGray'}>
        {text}
      </EyeBrowText>
    </Container>
  )
}
