import { darken } from 'polished'
import React, { ReactNode } from 'react'
import { layout, LayoutProps } from 'styled-system'

import styled from '../../styles/styled'

type ButtonType = 'reset' | 'submit'

interface IContainerProps extends LayoutProps {
  type?: ButtonType
  onClick?: () => void
  disabled?: boolean
  outlined?: boolean
}

interface IButtonProps extends IContainerProps {
  children: ReactNode
}

const Container = styled.button<IContainerProps>`
  // Using styled-system for layout props: https://styled-system.com/#layout
  ${layout}

  background: ${props => props.theme.colors.darkBlue};
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  text-transform: capitalize;
  padding: 0.6rem 1.5rem;
  border-radius: 9999px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  box-shadow: none;
  font-size: ${props => props.theme.fontsizes.xs};
  transition: ${props => props.theme.basicTransition};
  border: none;
  font-weight: 500;

  &:hover,
  &:focus,
  &:active {
    background: ${props => darken(0.1, props.theme.colors.darkBlue)};
  }

  &:disabled {
    background: ${props => props.theme.colors.gray1};
  }
`

export const Button = ({ children, ...rest }: IButtonProps) => (
  <Container {...rest}>{children}</Container>
)
